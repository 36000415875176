@media (max-width: 450px) {
  .list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
  }
}

.list {
  list-style-type: none;
  padding: 0;
  width: 100%;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 35px;
  row-gap: 15px;
  margin-bottom: 30px;
  justify-items: stretch;
}
.li {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 50%;
  aspect-ratio: 1 / 1; /* Utrzymuje stały stosunek szerokości do wysokości */
  -webkit-box-shadow: 11px 11px 15px -5px rgba(66, 68, 90, 1);
  -moz-box-shadow: 11px 11px 15px -5px rgba(66, 68, 90, 1);
  box-shadow: 5px 5px 15px -5px rgba(66, 68, 90, 1);
}
.link {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.li:hover {
  background: rgb(224, 224, 224);
  background: -moz-linear-gradient(
    90deg,
    rgba(224, 224, 224, 1) 29%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(224, 224, 224, 1) 29%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(224, 224, 224, 1) 29%,
    rgba(255, 255, 255, 1) 100%
  );
  -webkit-box-shadow: inset 7px 7px 19px -13px rgba(66, 68, 90, 1);
-moz-box-shadow: inset 7px 7px 19px -13px rgba(66, 68, 90, 1);
box-shadow: inset 2px 2px 3px 0px #919191;
  color: #d63744;
}
.selected {
  /* background-color: rgba(58, 58, 58, 0.6); */
  background: rgb(224, 224, 224);
  background: -moz-linear-gradient(
    90deg,
    rgba(224, 224, 224, 1) 29%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(224, 224, 224, 1) 29%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(224, 224, 224, 1) 29%,
    rgba(255, 255, 255, 1) 100%
  );
  -webkit-box-shadow: inset 7px 7px 19px -13px rgba(66, 68, 90, 1);
  -moz-box-shadow: inset 7px 7px 19px -13px rgba(66, 68, 90, 1);
  box-shadow: inset 2px 2px 3px 0px #919191;
}

h3 {
  font-size: 14px;
  margin: 0;
}
