.post{
    width: 200px;
    background-color: #d8d8d8;
    padding-bottom: 20px;
    margin-left: 25px;
    text-decoration: none;
    color: #3b3b3b;
    margin-bottom: 20px;
}
.category{
    padding-top: 10px;
    color: red;
    font-size: 10px;
    font-weight: 600;
    padding-left: 20px;
}
.posttitle{
    padding-left: 20px;
    padding-top: 10px;
    font-size: 14px;
    font-weight: 600;
}
.postdata{
    padding-top: 10px;
    padding-left: 30px;
    font-size: 9px;
}
.postdesc{
    font-size: 12px;
    padding-left: 20px;
    padding-right: 20px;
}
.link{
    font-weight: 600;
    font-size: 12px;
    padding: 5px;
    background-color: #d8d8d8;
    margin-left: 50px;
    border: none;
    color: #3b3b3b;

}
.link:hover{
    color: red;;
}
