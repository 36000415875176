.Header {
  margin: 0;
  display: flex;
  align-items: center;
  max-width: 700px;
  width: 100%;
  border-bottom: 3px solid #d63744;
  background-color: #e7e7e7;
  padding:20px 10px;
  border-radius: 3px;
}
