@media (max-width: 450px) {
  .buttonList {
    grid-template-columns: 1fr !important;
    gap: 5px;
  }
}
@media screen and (max-width: 768px) {
  .rightbar{
    display: none;
  }
  .mobileads{
    display: flex;
    justify-content: center;
  }
  .posts{
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
  }
}
@media screen and (min-width: 768px) {
  .rightbar{
    display: flex;
    align-items: center;
  }
  
  .mobileads{
    display: none;
  }
}


.button {
  padding: 10px;
  background-color: #e0e0e0;
}
.buttonList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
  margin-bottom: 30px;
}
.button {
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  transition: 0.4s;
  background-color: #e0e0e0;
  border-radius: 4px;
  -webkit-box-shadow: 11px 11px 7px -5px rgba(66, 68, 90, 1);
  -moz-box-shadow: 11px 11px 7px -5px rgba(66, 68, 90, 1);
  box-shadow: 5px 5px 7px -5px rgba(66, 68, 90, 1);
}
.button:hover,
.buttonActive {
  background: rgb(224, 224, 224);
  background: -moz-linear-gradient(
    90deg,
    rgba(224, 224, 224, 1) 29%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(224, 224, 224, 1) 29%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(224, 224, 224, 1) 29%,
    rgba(255, 255, 255, 1) 100%
  );
  /* box-shadow: none; */
  -webkit-box-shadow: inset 7px 7px 19px -13px rgba(66, 68, 90, 1);
-moz-box-shadow: inset 7px 7px 19px -13px rgba(66, 68, 90, 1);
box-shadow: inset 2px 2px 3px 0px #919191;
  color: #d63744;
}

.fullheight {
  margin-top: 30px;
}
.mobileads{
  height: 200px;
  margin-bottom: 30px;
}
.rightbar{
  /* border: solid 1px; */
  margin-left: 50px;
  width: 800px;
  height: 700px;
}
.posts{
  display: flex;
  /* margin-bottom: 80px; */
}

.blog {
  border-top: 3px solid #d63744;
  padding-top: 20px;
}