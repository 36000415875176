@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

* {
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

html {
  overflow-y: scroll;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  background-color: #e7e7e7;
  /* max-width: 700px; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  padding: 0 10px 10px 10px;
  max-width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
}

label {
  /* margin-top: 0; */
  padding-left: 10px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  font-size: 12px;
}

select {
  -webkit-appearance: none;
  appearance: none;
}

input,
select {
  padding: 10px 20px !important;
  width: 100%;
  border-radius: 4px;
  color: black;
  background-color: #fff;
  border: 1px solid black;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.linksdiv {
  padding: 20px 0;
  display: flex;
  gap: 20px;
}

.links {
  padding: 20px;
  background-color: #d53844;
  border: 1px solid #d53844;
  border-radius: 6px;
  color: white;
  text-decoration: none;
  transition: 0.6s;
}

.links:hover {
  background-color: transparent;
  color: black;
}
.obliczenia {
  max-width: 900px;
  width: 100%;
}
/* .sectionCalculate {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
} */

.topItem {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-top: 10px;
}

.topItemRight {
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.itemName {
  text-transform: uppercase;
  color: #919191;
  font-weight: 600;
  font-size: 25px;
  margin: 0;
}

@media (max-width: 450px) {
  .itemName {
    font-size: 16px;
  }

  .pweight {
    font-size: 13px !important;
  }
  .redWeight {
    font-size: 25px !important;
  }
}

.obliczenia section {
  flex-direction: column;
  gap: 20px !important;
}
@media (max-width: 450px) {
  .topItem {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .label {
    font-size: 13px;
  }
  footer {
    font-size: 10px;
  }
}

.details {
  width: 100%;
  max-width: 900px;
  /* padding: 0 15px; */
  /* border-radius: 26px; */
  /* -webkit-box-shadow: inset 7px 7px 19px -13px rgba(66, 68, 90, 1);
  -moz-box-shadow: inset 7px 7px 19px -13px rgba(66, 68, 90, 1);
  box-shadow: inset 1px 1px 5px 0px #919191; */
  color: #919191;
  /* background: rgb(224, 224, 224);
  background: -moz-linear-gradient(
    142deg,
    rgba(224, 224, 224, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    142deg,
    rgba(224, 224, 224, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    142deg,
    rgba(224, 224, 224, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  ); */
}

.info {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 3px solid #b1b1b1;
  color: #919191;
  font-size: 12px;
  font-weight: 600;
}

.weight {
  color: black;
  font-size: 16px;
  font-weight: 600;
  margin-left: 20px;
}
.pweight {
  color: black;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px 0;
}

.redWeight {
  color: #d63744;
  font-size: 40px;
  font-weight: 900;
  text-align: right;
  line-height: 1;
}

input,
select {
  background: rgb(224, 224, 224);
  background: -moz-linear-gradient(
    142deg,
    rgba(224, 224, 224, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    142deg,
    rgba(224, 224, 224, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    142deg,
    rgba(224, 224, 224, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  border: 0;
  color: #919191;
  -webkit-box-shadow: inset 7px 7px 19px -13px rgba(66, 68, 90, 1);
  -moz-box-shadow: inset 7px 7px 19px -13px rgba(66, 68, 90, 1);
  box-shadow: inset 1px 1px 5px 0px #919191;
}

select,
option,
input {
  color: #919191;
}

input:focus,
input:hover,
select:hover,
select:focus {
  outline: 1px solid #b1b1b1;
}

.textinputlabel{
  margin-left: 10px;
  margin-top: 10px;
}