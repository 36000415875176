.container{
    width: 100%;
    height: 100px;
    border-top: 3px solid #d63744;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blogbutton{
    font-size: 15px;
    padding: 15px;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    transition: 0.4s;
    background-color: #e0e0e0;
    border-radius: 4px;
    -webkit-box-shadow: 11px 11px 7px -5px rgba(66, 68, 90, 1);
    -moz-box-shadow: 11px 11px 7px -5px rgba(66, 68, 90, 1);
    box-shadow: 5px 5px 7px -5px rgba(66, 68, 90, 1);
}